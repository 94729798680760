// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "./controllers";
import { Turbo } from "@hotwired/turbo-rails";

import Bridge from "./turbo/bridge";
window.bridge = Bridge;

// <turbo-stream action="redirect" target="/projects"><template></template></turbo-stream>
// turbo_stream.action(:redirect, projects_path)
Turbo.StreamActions.redirect = function () {
  Turbo.visit(this.target);
};

// if (
//   !document.querySelector("html").classList.contains("dark") &&
//   !document.querySelector("html").classList.contains("light") &&
//   window.matchMedia("(prefers-color-scheme: dark)").matches
// ) {
//   document.querySelector("html").classList.add("dark");
// }
