import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["el"];

  click(e) {
    e.preventDefault();

    this.elTarget.click();
  }

  clickById(e) {
    e.preventDefault();

    if (e.params.id) {
      const el = document.getElementById(e.params.id);
      if (el) el.click();
    }
  }
}
