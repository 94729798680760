import { Controller } from "@hotwired/stimulus";
import { patch } from "@rails/request.js";

export default class extends Controller {
  static targets = ["category"];

  toggle(e) {
    e.preventDefault();
    if (this.categoryTargets[e.params.index].classList.contains("active")) {
      this.categoryTargets[e.params.index].classList.remove("active");
      this.closeAllExcept(e.params.index);
    } else {
      this.categoryTargets[e.params.index].classList.add("active");
      this.closeAllExcept(e.params.index);
    }
  }

  closeAllExcept(index) {
    this.categoryTargets.forEach((c, i) => {
      if (i !== index) {
        c.classList.remove("active");
      }
    });
  }

  async serviceClick(e) {
    e.preventDefault();

    try {
      patch(e.params.url, { responseKind: "turbo-stream" });
    } catch (e) {
      console.log(e);
    }
  }
}
