import { Application } from "@hotwired/stimulus";
import Dropdown from "@stimulus-components/dropdown";
import RevealController from "@stimulus-components/reveal";

const application = Application.start();

application.register("dropdown", Dropdown);
application.register("reveal", RevealController);

// Configure Stimulus development experience
application.warnings = true;
application.debug = false;
window.Stimulus = application;

export { application };
