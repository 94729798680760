import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["price"];

  setPrice(e) {
    if (e.params.price) {
      this.priceTarget.innerHTML = e.params.price;
    } else if (e.target.value) {
      const value = parseInt(e.target.value);
      if (value <= 0) {
        e.preventDefault();
        return;
      }

      this.priceTarget.innerHTML = (value * 40).toLocaleString("es-DO", {
        style: "currency",
        currency: "DOP",
      });
    }
  }
}
