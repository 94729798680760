import { Controller } from "@hotwired/stimulus";
import { debounce } from "../helpers";

export default class extends Controller {
  static targets = ["serviceSuggestion", "list"];
  // static values = {
  //   src: String,
  //   minLength: { type: Number, default: 0 },
  //   enterDisabled: { type: Boolean, default: false },
  // };

  toggle(e) {
    e.preventDefault();
    this.listTarget.hidden = !this.listTarget.hidden;
  }

  itemClick(e) {
    if (e.target.children[0].classList.contains("fa-square")) {
      e.target.children[0].classList.remove("fa-square");
      e.target.children[0].classList.add("fa-square-check");
    } else {
      e.target.children[0].classList.add("fa-square");
      e.target.children[0].classList.remove("fa-square-check");
    }
    this.listTarget.hidden = true;
  }

  toggleProfileSuggestionForm(e) {
    e.preventDefault();
    if (
      this.serviceSuggestionTarget.classList.contains(
        "professions-new-service-form--active",
      )
    ) {
      this.serviceSuggestionTarget.classList.remove(
        "professions-new-service-form--active",
      );
    } else {
      this.serviceSuggestionTarget.classList.add(
        "professions-new-service-form--active",
      );
    }
  }
}
