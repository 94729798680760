import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="subscription-intro"
export default class extends Controller {
  static targets = [
    "container",
    "loadingLabel",
    "plan",
    "stepOneLabel",
    "action",
    "step",
  ];

  initialize() {
    localStorage.setItem("workActivities", JSON.stringify(this.workActivities));
    this.step = localStorage.getItem("subStep")
      ? parseInt(localStorage.getItem("subStep"), 10)
      : 0;
  }

  connect() {
    if (!this.hasContainerTarget) return;

    if (this.step === 1) {
      this.stepTargets[0].classList.add("opacity-0");
      this.stepTargets[0].classList.add("hidden");
      this.stepTargets[1].classList.remove("hidden");
      this.stepTargets[1].classList.remove("opacity-0");
      this.actionTargets[1].classList.remove("hidden");
      this.actionTargets[1].classList.remove("opacity-0");
      return;
    }

    if (this.step === 2) {
      this.stepTargets[0].classList.add("opacity-0");
      this.stepTargets[0].classList.add("hidden");
      this.stepTargets[1].classList.add("opacity-0");
      this.stepTargets[1].classList.add("hidden");
      this.stepTargets[2].classList.remove("hidden");
      this.stepTargets[2].classList.remove("opacity-0");
      this.actionTargets[2].classList.remove("hidden");
      this.actionTargets[2].classList.remove("opacity-0");
      return;
    }

    if (this.step === 3) {
      this.containerTarget.classList.add("hidden");
      this.planTarget.classList.remove("hidden");
      return;
    }

    setTimeout(() => {
      this.loadingLabelTarget.classList.remove("opacity-0");

      setTimeout(() => {
        this.loadingLabelTarget.classList.add("opacity-0");
        this.loadingLabelTarget.innerText =
          "Analizando potencial en el mercado...";
        this.loadingLabelTarget.classList.remove("opacity-0");

        setTimeout(() => {
          this.loadingLabelTarget.classList.add("opacity-0");
          this.loadingLabelTarget.innerText = "Finalizando la propuesta.";
          this.loadingLabelTarget.classList.remove("opacity-0");
        }, 8000);
      }, 5000);
    }, 500);

    // Step 1
    setTimeout(() => {
      this.stepOneLabelTargets[0].classList.add("top-0");
      this.stepOneLabelTargets[0].classList.remove("top-[100dvh]");

      setTimeout(() => {
        this.stepOneLabelTargets[1].classList.add("top-0");
        this.stepOneLabelTargets[1].classList.remove("top-[100dvh]");

        setTimeout(() => {
          this.stepOneLabelTargets[2].classList.add("top-0");
          this.stepOneLabelTargets[2].classList.remove("top-[100dvh]");

          setTimeout(() => {
            this.actionTargets[0].classList.remove("hidden");

            setTimeout(() => {
              this.actionTargets[0].classList.remove("opacity-0");
            }, 100);
          }, 7000);
        }, 7000);
      }, 6000);
    }, 3000);
  }

  next() {
    if (this.step === 0) {
      this.step = 1;
      localStorage.setItem("subStep", this.step);
      this.stepTargets[0].classList.add("opacity-0");

      setTimeout(() => {
        this.stepTargets[0].classList.add("hidden");
        this.stepTargets[1].classList.remove("hidden");

        setTimeout(() => {
          this.stepTargets[1].classList.remove("opacity-0");
        }, 50);

        setTimeout(() => {
          this.actionTargets[1].classList.remove("hidden");
          setTimeout(() => {
            this.actionTargets[1].classList.remove("opacity-0");
          }, 100);
        }, 500);
      }, 50);
    } else if (this.step === 1) {
      this.step = 2;
      localStorage.setItem("subStep", this.step);
      this.stepTargets[1].classList.add("opacity-0");

      setTimeout(() => {
        this.stepTargets[1].classList.add("hidden");
        this.stepTargets[2].classList.remove("hidden");

        setTimeout(() => {
          this.stepTargets[2].classList.remove("opacity-0");
        }, 50);

        setTimeout(() => {
          this.actionTargets[2].classList.remove("hidden");
          setTimeout(() => {
            this.actionTargets[2].classList.remove("opacity-0");
          }, 100);
        }, 500);
      }, 50);
    } else if (this.step === 2) {
      this.step = 3;
      localStorage.setItem("subStep", this.step);
      this.containerTarget.classList.add("hidden");
      this.planTarget.classList.remove("hidden");
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }
}
