import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["select"];
  static values = {
    url: String,
    param: String,
    recordId: String,
    newResourceUrl: String,
  };

  connect() {
    if (this.selectTarget.id === "") {
      this.selectTarget.id = Math.random().toString(36);
    }
  }

  change(event) {
    if (
      event.target.selectedOptions[0].value === "0" &&
      this.newResourceUrlValue
    ) {
      window.Turbo.visit(this.newResourceUrlValue);
      return;
    }

    let params = new URLSearchParams();
    params.append(this.paramValue, event.target.selectedOptions[0].value);
    params.append("target", this.selectTarget.id);

    if (this.recordIdValue) {
      params.append("record_id", this.recordIdValue);
    }

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream",
    });
  }
}
