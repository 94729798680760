import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String,
    text: String,
    title: String,
  };

  async open(e) {
    e.preventDefault();

    const payload = {
      text: `${this.titleValue}\n\n${this.textValue}\n\n${this.urlValue}`,
    };

    try {
      if (!navigator.canShare) {
      } else if (navigator.canShare(payload)) {
        navigator.share(payload);
      } else {
        console.log("Can't share");
      }
    } catch (e) {}
  }
}
