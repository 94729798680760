import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["copyField", "copyText"];

  contact(e) {
    const value = e.params.value.toString();

    if (value.includes("@")) {
      this.copyTextTarget.value = value;
    } else {
      this.copyTextTarget.value = `${
        value[0] === "1" || value[0] === "+" ? "" : "1"
      }${value}`;
    }

    this.copyFieldTarget.classList.add("active");
  }
}
