import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["display"];

  change(e) {
    e.preventDefault();
    if (!e.target.files[0]) return;
    this.displayTarget.src = URL.createObjectURL(e.target.files[0]);
  }
}
