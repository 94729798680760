import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="text-clip"
export default class extends Controller {
  initialize() {
    this.original = this.element.innerHTML;
  }

  connect() {
    if (this.element.innerHTML.length > 400) {
      this.element.innerHTML =
        this.element.innerHTML.slice(0, 360) +
        `... <a class="text-sky-600 cursor-pointer" data-action="text-clip#toggle">Leer más</a>`;
    }
  }

  toggle() {
    this.element.innerHTML = this.original;
  }
}
