import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];
  static values = { serviceId: String };

  search() {
    this.buttonTarget.click();
  }

  landingSearch(e) {
    window.Turbo.visit(`/contratar/${this.serviceIdValue}/${e.target.value}`);
  }
}
