import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["newPaymentMethod", "paymentMethodSelect", "total"];
  static values = {
    hasPaymentMethods: Boolean,
    productPrice: { type: Number, default: 0 },
  };

  initialize() {
    this.cvv = "";
  }

  connect() {
    if (
      !this.hasPaymentMethodSelectTarget ||
      !this.paymentMethodSelectTarget.value
    ) {
      this.newPaymentMethodTarget.classList.remove("hidden");
      this.newPaymentMethodTarget.classList.remove("opacity-0");
    }
  }

  formatCvv(e) {
    if (e.target.value.length > 4) {
      e.target.value = this.cvv;
      return;
    }

    this.cvv = e.target.value.replace(/[^0-9]/g, "");
    e.target.value = this.cvv;
  }

  formatExpiration(e) {
    e.target.value = e.target.value
      .replace(/[^0-9]/g, "")
      .replace(/^([2-9])$/g, "0$1")
      .replace(/^(1{1})([3-9]{1})$/g, "0$1/$2")
      .replace(/^0{1,}/g, "0")
      .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, "$1/$2");
  }

  onPaymentMethodChange(e) {
    if (e.target.value !== "") {
      this.newPaymentMethodTarget.classList.add("opacity-0");
      setTimeout(() => {
        this.newPaymentMethodTarget.classList.add("hidden");
      }, 300);
    } else {
      this.newPaymentMethodTarget.classList.remove("hidden");
      setTimeout(() => {
        this.newPaymentMethodTarget.classList.remove("opacity-0");
      }, 200);
    }
  }

  onQuantityChange(e) {
    this.totalTarget.innerHTML = `<span>RD$ </span> ${(
      parseInt(e.target.value, 10) * this.productPriceValue
    ).toLocaleString("es-DO", {
      minimumFractionDigits: 2,
      currency: "DOP",
    })}`;
  }
}
