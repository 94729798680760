import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";

Swiper.use([Pagination, Navigation]);

export default class extends Controller {
  connect() {
    new Swiper(".highlighted-offers-swiper", {
      spaceBetween: 30,
      navigation: {
        nextEl: ".highlighted-offers-swiper-navigation__next",
        prevEl: ".highlighted-offers-swiper-navigation__prev",
      },
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
      },
      breakpoints: {
        // when window width is >= 480px
        576: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        // when window width is >= 640px
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
      },
    });
  }
}
