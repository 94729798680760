import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Pagination } from "swiper/modules";

Swiper.use([Pagination]);

export default class extends Controller {
  initialize() {
    this.observer = null;
    this.observerLeft = null;
  }

  connect() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-intersect-active");
        }
      });
    });
    document
      .querySelectorAll(".animate-intersect")
      .forEach((e) => this.observer.observe(e));

    this.observerLeft = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-intersect-left-active");
        }
      });
    });
    document
      .querySelectorAll(".animate-intersect-left")
      .forEach((e) => this.observerLeft.observe(e));
  }

  disconnect() {
    this.observer.disconnect();
    this.observerLeft.disconnect();
  }
}
