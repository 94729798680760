import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];
  static values = {
    redirectPath: String,
    isRegistering: { type: Boolean, default: false },
  };

  connect() {
    document.addEventListener("AppleIDSignInOnSuccess", (data) => {
      let uri = `${this.redirectPathValue}?code=${data.detail.authorization.code}&id_token=${data.detail.authorization.id_token}&state=${data.detail.authorization.state}`;

      if (data.detail.user) {
        if (data.detail.user.email.includes("privaterelay")) {
          window.alert(
            "Para que nuestros suplidores puedan contactarte, debes dejar visible tu correo"
          );
          return;
        }
        uri += `&firstName=${data.detail.user.name.firstName}&lastName=${
          data.detail.user.name.lastName || ""
        }`;
      }

      window.location = uri;
    });

    document.addEventListener("AppleIDSignInOnFailure", (error) => {
      window.alert("No logramos iniciar sesiónc on Apple, vuelve a intentarlo");
    });
  }

  init(e) {
    e.preventDefault();
    if (
      this.isRegisteringValue &&
      !window.confirm(
        "Para que nuestros suplidores puedan contactarte, debes dejar visible tu correo"
      )
    ) {
      alert("Es obligatorio compartir tu correo con la plataforma");
      return;
    }
    this.buttonTarget.click();
  }
}
