import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "action"];
  static values = { length: Number, threshold: Number };

  connect() {
    if (this.lengthValue < this.thresholdValue) {
      this.actionTarget.remove();
      return;
    }
    // if (!this.contentTarget.classList.contains("hidden-text")) {
    //   this.contentTarget.classList.add("hidden-text");
    // }
  }

  toggle(e) {
    e.preventDefault();
    if (this.contentTarget.classList.contains("hidden-text")) {
      this.contentTarget.classList.remove("hidden-text");
      this.actionTarget.innerHTML = `Ocultar <span class="icon icon-chevron-up"></span>`;
    } else {
      this.contentTarget.classList.add("hidden-text");
      this.actionTarget.innerHTML = `Sobre nosotros <span class="icon icon-chevron-down"></span>`;
    }
  }
}
