import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "otroField"];

  option(e) {
    this.otroFieldTargets[e.params.index].value = e.params.value;
    this.otroFieldTargets[e.params.index].setAttribute("hidden", true);
    this.formTarget.submit();
  }

  submit(e) {
    e.preventDefault();
    this.formTarget.submit();
  }

  otros(e) {
    this.otroFieldTargets[e.params.index].value = "";
    this.otroFieldTargets[e.params.index].removeAttribute("hidden");
  }

  input(e) {
    this.otroFieldTargets[e.params.index].value = e.target.value;
    this.otroFieldTargets[e.params.index].setAttribute("hidden", true);
  }
}
