import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Navigation } from "swiper/modules";

Swiper.use([Navigation]);

// Connects to data-controller="swiper"
export default class extends Controller {
  static values = {
    slidesPerView: { type: Number, default: 1 },
  };

  connect() {
    console.log(typeof this.slidesPerViewValue);

    new Swiper(this.element, {
      // spaceBetween: 20,
      slidesPerView: this.slidesPerViewValue,
      // centeredSlides: true,
      initialSlide: 1,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }
}
