import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    autoLoad: { type: Boolean, default: false },
  };

  // initialize() {
  // this.onKeydown = this.onKeydown.bind(this);
  // }

  connect() {
    // window.addEventListener("keydown", this.onKeydown);
    if (this.autoLoadValue) {
      document.body.classList.add("overflow-y-hidden");
    }
  }

  open() {
    document.body.classList.add("overflow-y-hidden");
  }

  close(e) {
    // window.removeEventListener("keydown", this.onKeydown);
    e.preventDefault();
    this.element.remove();
    document.body.classList.remove("overflow-y-hidden");
  }

  closeClassic(e) {
    // window.removeEventListener("keydown", this.onKeydown);
    e.preventDefault();
    this.element.classList.add("hidden");
    document.body.classList.remove("overflow-y-hidden");
  }

  // onKeydown(e) {
  //   if (e.key === "Escape") {
  //     this.close(e);
  //   }
  // }
}
