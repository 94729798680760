import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  initialize() {
    this.toggle = this.toggle.bind(this);
  }

  connect() {
    const sidebarToggle = document.getElementById("sidebar-toggle");
    if (!sidebarToggle) return;
    sidebarToggle.addEventListener("click", this.toggle);
  }

  disconnect() {
    const sidebarToggle = document.getElementById("sidebar-toggle");
    if (!sidebarToggle) return;
    sidebarToggle.removeEventListener("click", this.toggle);
  }

  toggle(e) {
    e.preventDefault();
    this.element.classList.toggle("max-xl:-left-full");
    this.element.classList.toggle("left-0");
  }
}
