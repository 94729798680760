import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

// Connects to data-controller="read-chat"
export default class extends Controller {
  static values = { url: String, unread: Boolean };

  async connect() {
    if (!this.unreadValue || this.read) return;
    try {
      post(this.urlValue);
      this.read = true;
    } catch (err) {
      console.log(err);
    }
  }
}
