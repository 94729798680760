import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static values = { key: Array };

  connect() {
    if (!("PushManager" in window)) return;

    if (!("Notification" in window)) {
      console.error("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      console.log("Permission to receive notifications has been granted");
      this.getKeys();
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission((permission) => {
        if (permission === "granted") {
          console.log("Permission to receive notifications has been granted");
          this.getKeys();
        }
      });
    }
  }

  async getKeys() {
    try {
      const vapidPublicKey = new Uint8Array(this.keyValue);

      const registration = await navigator.serviceWorker.register(
        "/serviceworker.js",
        { scope: "./" },
      );

      let subscription = await registration.pushManager.getSubscription();

      if (!subscription) {
        subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: vapidPublicKey,
        });
      }
      subscription = subscription.toJSON();

      post("/web_push_tokens", {
        body: JSON.stringify({
          web_push_token: {
            secret_endpoint: subscription.endpoint,
            secret_key: subscription.keys.auth,
            secret_token: subscription.keys.p256dh,
          },
        }),
      });
    } catch (err) {
      console.log(err);
    }
  }
}
