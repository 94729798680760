import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="modal-opener"
export default class extends Controller {
  async open(e) {
    const el = document.getElementById(e.params.id);
    if (el) {
      e.preventDefault();
      try {
        await get(e.target.href);
        el.classList.remove("hidden");
      } catch (e) {
        /* handle error */
      }
    }
  }
}
