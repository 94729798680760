import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="scrollable"
export default class extends Controller {
  initialize() {
    this.pos = { top: 0, left: 0, x: 0, y: 0 };
    this.mouseDownHandler = this.mouseDownHandler.bind(this);
    this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
    this.mouseUpHandler = this.mouseUpHandler.bind(this);
  }

  connect() {
    this.element.addEventListener("mousedown", this.mouseDownHandler);
  }

  mouseDownHandler(e) {
    this.element.style.cursor = "grabbing";
    this.element.style.userSelect = "none";

    this.pos = {
      left: this.element.scrollLeft,
      top: this.element.scrollTop,
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener("mousemove", this.mouseMoveHandler);
    document.addEventListener("mouseup", this.mouseUpHandler);
  }

  mouseMoveHandler(e) {
    const dx = e.clientX - this.pos.x;
    const dy = e.clientY - this.pos.y;
    this.element.scrollTop = this.pos.top - dy;
    this.element.scrollLeft = this.pos.left - dx;
  }

  mouseUpHandler() {
    document.removeEventListener("mousemove", this.mouseMoveHandler);
    document.removeEventListener("mouseup", this.mouseUpHandler);

    this.element.style.cursor = "grab";
    this.element.style.removeProperty("user-select");
  }
}
