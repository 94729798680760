import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { alert: String };

  initialize() {
    this.onFileAccept = this.onFileAccept.bind(this);
  }

  connect() {
    document.addEventListener("trix-file-accept", this.onFileAccept);
  }

  disconnect() {
    document.removeEventListener("trix-file-accept", this.onFileAccept);
  }

  onFileAccept(e) {
    e.preventDefault();
    if (this.alertValue) window.alert(this.alertValue);
  }
}
