import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Navigation, Thumbs } from "swiper/modules";

Swiper.use([Navigation, Thumbs]);

export default class extends Controller {
  static targets = ["swiper", "thumbs"];

  connect() {
    // new Swiper(this.swiperTarget, {
    //   navigation: {
    //     nextEl: ".swiper-button-next",
    //     prevEl: ".swiper-button-prev",
    //   },
    // });

    const thumbs = new Swiper(this.thumbsTarget, {
      loop: false,
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    });

    new Swiper(this.swiperTarget, {
      loop: true,
      // spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: thumbs,
      },
    });

  }
}
