import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static values = { productId: String };

  initialize() {
    this.has_been_viewed = false;
    this.onScroll = this.onScroll.bind(this);
  }

  connect(e) {
    document.addEventListener("scroll", this.onScroll);
  }

  disconnect() {
    document.removeEventListener("scroll", this.onScroll);
  }

  onScroll(e) {
    if (this.has_been_viewed) return;
    if (this.elementIsVisibleInViewport(this.element)) {
      this.has_been_viewed = true;
      post(`/productos/${this.productIdValue}/impresiones`);
    }
  }

  elementIsVisibleInViewport(el, partiallyVisible = false) {
    const { top, left, bottom, right } = el.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
    return partiallyVisible
      ? ((top > 0 && top < innerHeight) ||
          (bottom > 0 && bottom < innerHeight)) &&
          ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
      : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
  }
}
