import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  click(e) {
    post(
      `/contact_intents?profile_id=${e.params.profileId}` +
        `&kind=${e.params.kind}` +
        `&source_type=${e.params.sourceType}` +
        `&source_id=${e.params.sourceId}`
    );
  }
}
