import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["active"];

  connect() {
    if (this.element) {
      setTimeout(() => {
        this.element.classList.add(this.activeClass);
      }, 100);
      setTimeout(() => {
        if (this.element) {
          this.element.remove();
        }
      }, 5000);
    }
  }

  remove() {
    this.element.remove();
  }
}
