import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="work-activity-notifications"
export default class extends Controller {
  initialize() {
    this.addNotification = this.addNotification.bind(this);
    this.workActivities =
      JSON.parse(localStorage.getItem("workActivities")) || [];
    this.notifGenerator = null;
  }

  async connect() {
    setTimeout(this.addNotification, 10000);
    setTimeout(setInterval(this.addNotification, 20000), 10000);

    if (
      !this.workActivities ||
      this.workActivities.filter((item) => !item.seen).length === 0
    ) {
      const res = await get("/work_activity_notifications.json");
      const data = await res.json;

      this.workActivities = data.map((item) => ({ ...item, seen: false }));

      localStorage.setItem(
        "workActivities",
        JSON.stringify(this.workActivities),
      );
    }
  }

  addNotification() {
    const workAcitivy = this.workActivities.find((item) => !item.seen);
    if (!workAcitivy) return;

    const notifEl = document.createElement("div");
    notifEl.classList.add(
      "transition-all",
      "animate-appear",
      "w-full",
      "sm:w-[430px]",
      "grid",
      "grid-cols-[auto_1fr_auto]",
      "items-start",
      "space-x-3",
      "bg-gray-200/75",
      "backdrop-blur",
      "p-3",
      "shadow-lg",
      "rounded-[10px]",
      "text-base",
      "z-50",
    );
    notifEl.setAttribute("data-controller", "disappear");
    notifEl.innerHTML = `
    <div class="overflow-hidden rounded-xl bg-white w-12 h-12">
      <img alt="Logo de ${workAcitivy.name}" class="w-full h-full object-cover" src="${workAcitivy.avatar}">
    </div>

    <div class"flex-1">
      <div class="text-sm font-black">Para ${workAcitivy.name}</div>
      <div class="text-sm font-normal">${workAcitivy.body}</div>
    </div>

    <div class="flex-1 text-xs font-normal">${workAcitivy.timeAgo}</div>`;

    this.element.append(notifEl);

    this.workActivities = this.workActivities.map((item) =>
      item.id === workAcitivy.id ? { ...item, seen: true } : item,
    );

    localStorage.setItem("workActivities", JSON.stringify(this.workActivities));
  }
}
