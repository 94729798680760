import { Controller } from "@hotwired/stimulus";
import html2canvas from "html2canvas";

// Connects to data-controller="screenshot"
export default class extends Controller {
  static targets = ["download", "container"];

  async load(e) {
    e.preventDefault();

    const style = document.createElement("style");
    document.head.appendChild(style);
    style.sheet?.insertRule(
      "body > div:last-child img { display: inline-block; }",
    );

    const canvas = await html2canvas(this.containerTarget, {
      // width: 500,
      // height: 888,
      letterRendering: 1,
      useCORS: true,
      allowTaint: true,
    });
    style.remove();

    const imageData = canvas.toDataURL("image/jpg");
    const newData = imageData.replace(
      /^data:image\/jpg/,
      "data:application/octet-stream",
    );

    this.downloadTarget.setAttribute("download", "image.jpg");
    this.downloadTarget.setAttribute("href", newData);
    this.downloadTarget.classList.add("block");
    this.downloadTarget.classList.remove("hidden");
  }

  click() {
    navigator.share(payload);
  }
}
