import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      spaceBetween: 20,
      slidesPerView: 1,
      centeredSlides: true,
      initialSlide: 1,
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        576: {
          slidesPerView: 3,
          // slidesPerGroup: 2,
        },
      },
    });
  }
}
