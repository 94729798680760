import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu"];
  static classes = ["active"];

  initialize() {
    this.onClickOutside = this.onClickOutside.bind(this);
  }

  connect() {
    if (this.menuTarget.classList.contains(this.activeClass)) {
      this.menuTarget.classList.remove(this.activeClass);
      window.removeEventListener("click", this.onClickOutside);
    }
  }

  toggle(e) {
    e.preventDefault();
    if (this.menuTarget.classList.contains(this.activeClass)) {
      this.menuTarget.classList.remove(this.activeClass);
      window.removeEventListener("click", this.onClickOutside);
    } else {
      this.menuTarget.classList.add(this.activeClass);
      window.addEventListener("click", this.onClickOutside);
    }
  }

  onClickOutside(e) {
    if (
      !this.element.contains(e.target) &&
      this.menuTarget.classList.contains(this.activeClass)
    ) {
      this.menuTarget.classList.remove(this.activeClass);
      window.removeEventListener("click", this.onClickOutside);
    }
  }
}
