import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { to: String };

  initialize() {
    this.scroll = this.scroll.bind(this);
  }

  connect() {
    this.element.addEventListener("click", this.scroll);
  }

  disconnect() {
    this.element.removeEventListener("click", this.scroll);
  }

  scroll(e) {
    e.preventDefault();

    const el = document.querySelector(this.toValue);

    if (el) {
      const rect = el.getBoundingClientRect();
      window.scrollTo({ top: rect.top, left: 0, behavior: "smooth" });
    }
  }
}
