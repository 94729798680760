import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["images", "image", "preview", "previewImage", "label", "videoLabel", "video", "videoLabelText", "videoPreview"];

  imageChange(e) {
    if (e.target.files.length === 0) return;
    this.previewImageTargets[e.params.index].src = URL.createObjectURL(e.target.files[0]);
    this.previewTargets[e.params.index].classList.remove("hidden");
    if (e.params.index !== 3) {
      this.labelTargets[e.params.index + 1].classList.remove("hidden")
      setTimeout(() => {
        this.labelTargets[e.params.index + 1].classList.remove("opacity-0")
      }, 50)
    }
  }

  imageRemove(e) {
    e.preventDefault();
    e.stopPropagation();
    this.previewImageTargets[e.params.index].src = "";
    this.previewTargets[e.params.index].classList.add("hidden");
    this.imageTargets[e.params.index].value = "";
  }

  videoChange(e) {
    if (e.target.files.length === 0) return;
    this.videoLabelTextTarget.innerHTML = `Video agregado <span><svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 text-red-500" viewBox="0 0 512 512"><path d="M112 112l20 320c.95 18.49 14.4 32 32 32h184c17.67 0 30.87-13.51 32-32l20-320" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M80 112h352" /><path d="M192 112V72h0a23.93 23.93 0 0124-24h80a23.93 23.93 0 0124 24h0v40M256 176v224M184 176l8 224M328 176l-8 224" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /></svg></span>`
    this.videoLabelTarget.classList.remove("border-blue-100")
    this.videoLabelTarget.classList.remove("bg-blue-50")
    this.videoLabelTarget.classList.add("border-green-100")
    this.videoLabelTarget.classList.add("bg-green-50")
    this.videoPreviewTarget.classList.remove("hidden");
  }

  videoRemove(e) {
    e.preventDefault();
    e.stopPropagation();
    this.videoLabelTextTarget.innerHTML = `Agregar video`
    this.videoTarget.value = "";
    this.videoLabelTarget.classList.add("border-blue-100")
    this.videoLabelTarget.classList.add("bg-blue-50")
    this.videoLabelTarget.classList.remove("border-green-100")
    this.videoLabelTarget.classList.remove("bg-green-50")
    this.videoPreviewTarget.classList.add("hidden");
  }
}
