import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="ai-profile-search-polling"
export default class extends Controller {
  static values = { url: String, disabled: Boolean };

  connect() {
    if (!this.disabledValue) {
      this.pollForCompletion();
    }
  }

  disconnect() {
    clearInterval(this.pollInterval);
  }

  pollForCompletion() {
    this.pollInterval = setInterval(async () => {
      try {
        const res = await get(this.urlValue);
        const data = await res.json;

        if (data.status === "completed" || data.status === "failed") {
          clearInterval(this.pollInterval);
          window.Turbo.visit(data.redirect_url, { action: "replace" });
        }
      } catch (e) {
        console.error("Polling error:", e);
      }
    }, 100);
  }
}
