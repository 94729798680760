import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["search", "compare", "contact"];

  connect() {
    this.searchTarget.addEventListener("click", () => {
      this.searchTarget.classList.add("how-it-works-item--active");
      this.compareTarget.classList.remove("how-it-works-item--active");
      this.contactTarget.classList.remove("how-it-works-item--active");
    });

    this.compareTarget.addEventListener("click", () => {
      this.compareTarget.classList.add("how-it-works-item--active");
      this.searchTarget.classList.remove("how-it-works-item--active");
      this.contactTarget.classList.remove("how-it-works-item--active");
    });

    this.contactTarget.addEventListener("click", () => {
      this.contactTarget.classList.add("how-it-works-item--active");
      this.compareTarget.classList.remove("how-it-works-item--active");
      this.searchTarget.classList.remove("how-it-works-item--active");
    });
  }
}
