import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Navigation, Thumbs } from "swiper/modules";

Swiper.use([Navigation, Thumbs]);

export default class extends Controller {
  static values = {
    index: { type: Number, default: 0 },
  };

  connect() {
    const thumbSwiper = new Swiper(".gallery-thumb-swiper-container", {
      initialSlide: this.indexValue,
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    });

    new Swiper(".gallery-swiper-container", {
      initialSlide: this.indexValue,
      spaceBetween: 10,
      navigation: {
        nextEl: ".gallery-swiper-navigation__next",
        prevEl: ".gallery-swiper-navigation__prev",
      },
      thumbs: {
        swiper: thumbSwiper,
        slideThumbActiveClass: "swiper-slide-thumb-active",
      },
    });
  }
}
