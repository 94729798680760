import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];
  static values = {
    autoLoad: { type: Boolean, default: true },
  };

  connect() {
    if (this.element.nodeName == "LI") return;
    if (this.autoLoadValue) document.body.classList.add("overflow-y-hidden");
  }

  disconnect() {
    document.body.classList.remove("overflow-y-hidden");
  }

  open(e) {
    e.preventDefault();
    this.modalTarget.showModal();
    document.body.classList.add("overflow-y-hidden");
  }

  close(e) {
    e.preventDefault();
    this.modalTarget.close();
    document.body.classList.remove("overflow-y-hidden");
  }

  openClassic(e) {
    e.preventDefault();
    this.modalTarget.classList.remove("hidden");
    this.modalTarget.classList.add("flex");
    document.body.classList.add("overflow-y-hidden");
    setTimeout(() => {
      this.modalTarget.classList.remove("opacity-0");
    }, 50);
  }

  closeClassic(e) {
    e.preventDefault();
    this.modalTarget.classList.add("opacity-0");
    document.body.classList.remove("overflow-y-hidden");
    setTimeout(() => {
      this.modalTarget.classList.add("hidden");
      this.modalTarget.classList.remove("flex");
    }, 300);
  }

  destroy(e) {
    if (!e.detail.success) return;

    this.element.remove();
    document.body.classList.remove("overflow-y-hidden");
    if (e.params.reload) window.Turbo.visit("/");
  }
}
