import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { cardId: String, currentColor: String };

  initialize() {
    this.currentColor = this.currentColorValue;
  }

  pick(e) {
    const profileCard = document.getElementById(this.cardIdValue);
    console.log(this.currentColor);
    console.log(e.params.color);
    profileCard.classList.remove(this.currentColor);
    profileCard.classList.add(e.params.color);
    this.currentColor = e.params.color;
  }
}
